<template>
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-md-8">
        <b-card :title="$t('message.title.customerCredit')">
          <b-form @submit.prevent="getCredits">
            <div class="row">
              <div class="col-sm-6 col-md-5">
                {{ $t("message.machineIdFarm") }} :
                <br />
                <multi-list-select
                  :list="machineData"
                  option-value="machineId"
                  option-text="name"
                  :selected-items="selectedMachine"
                  placeholder="All"
                  @select="onSelectMachine"
                ></multi-list-select>
              </div>
              <div class="col-sm-6 col-md-3">
                {{ $t("message.refNumber") }} :
                <br />
                <b-form-input v-model="searchData.refNumber" />
              </div>
              <div class="col-sm-2 col-md-4">
                <br />
                <b-btn variant="success" type="submit">
                  <i class="fa fa-search"></i>
                  &nbsp;{{ $t("message.search") }} </b-btn
                >&nbsp;&nbsp;
                <!-- <b-btn v-on:click="exportFn()" variant="primary" :disabled="true">
                  <i class="icon-docs"></i>
                  &nbsp;{{ $t("message.export") }}
                </b-btn> -->
              </div>
            </div>
          </b-form>
          <br />
          <div class="table-responsive">
            <table class="table-modal">
              <thead>
                <th style="text-align-last: center">
                  {{ $t("message.machineIdFarm") }}
                </th>
                <th style="text-align-last: center">
                  {{ $t("message.refNumber") }}
                </th>
                <th style="text-align-last: right">
                  {{ $t("message.creditBefore") }}
                </th>
                <th style="text-align-last: center">
                  {{ $t("message.createdAt") }}
                </th>
                <th style="text-align-last: center">
                  {{ $t("message.updatedAt") }}
                </th>
                <th style="text-align-last: center">
                  {{ $t("message.details") }}
                </th>
                <th style="text-align-last: center">
                  {{ $t("message.resetPassword") }}
                </th>
              </thead>
              <tbody>
                <template v-for="items in rowData">
                  <tr>
                    <td style="text-align: center">{{ items.machineId }}</td>
                    <td style="text-align: center">{{ items.refNumber }}</td>
                    <td style="text-align: right">{{ items.value }}</td>
                    <td style="text-align: center">
                      {{ items.createdAt | moment("YYYY-MM-DD HH:mm") }}
                    </td>
                    <td style="text-align: center">
                      {{ items.updatedAt | moment("YYYY-MM-DD HH:mm") }}
                    </td>
                    <td style="text-align: center">
                      <b-btn variant="warning" v-on:click="showModal(items)">{{
                        $t("message.details")
                      }}</b-btn>
                    </td>
                    <td style="text-align: center">
                      <b-btn
                        variant="danger"
                        v-on:click="showResetPasswordModal(items)"
                        >{{ $t("message.resetPassword") }}</b-btn
                      >
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <br />
          <b-pagination
            size="md"
            :total-rows="totalRows"
            v-model="pagination"
            :per-page="itemperPage"
            @input="pageChanged(pagination)"
          ></b-pagination>
        </b-card>
      </div>

      <div class="col-sm-6 col-md-4">
        <b-card :title="$t('message.addOrDeductCredit')">
          <b-form @submit.prevent="manageCredit">
            <b-form-group
              :label="$t('message.type')"
              label-for="type"
              description
            >
              <b-form-select
                v-model="creditData.type"
                id="type"
                :options="type"
                required
              ></b-form-select>
            </b-form-group>
            <b-form-group
              :label="$t('message.refNumber')"
              label-for="refNumberr"
              description
            >
              <b-form-input
                v-model="creditData.refNumber"
                id="refNumberr"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              :label="$t('message.machineId')"
              label-for="machineId"
              description
            >
              <model-list-select
                id="machineId"
                :list="machineData"
                v-model="creditData.machineId"
                option-value="machineId"
                option-text="name"
                placeholder="Select Machine ID"
                required
              ></model-list-select>
            </b-form-group>
            <b-form-group
              :label="$t('message.amount')"
              label-for="amount"
              description
            >
              <b-form-input
                v-model.number="creditData.price"
                id="amount"
                type="number"
                required
                min="0"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              :label="$t('message.note')"
              label-for="detail"
              description
            >
              <b-form-textarea
                v-model.number="creditData.note"
                id="detail"
                type="text"
              ></b-form-textarea>
            </b-form-group>
            <b-btn variant="primary" type="submit">{{
              $t("message.confirm")
            }}</b-btn>
          </b-form>
        </b-card>
      </div>
    </div>

    <b-modal ref="detailsModal" :title="$t('message.details')" size="lg">
      <div class="table-responsive">
        <table class="table-modal">
          <thead style="text-align-last: center">
            <th>id</th>
            <th>{{ $t("message.refNumber") }}</th>
            <th>{{ $t("message.type") }}</th>
            <th style="text-align-last: right">{{ $t("message.price") }}</th>
            <th style="text-align-last: right">{{ $t("message.insert") }}</th>
            <th style="text-align-last: right">{{ $t("message.credit") }}</th>
            <th style="text-align-last: right">{{ $t("message.changes") }}</th>
            <th style="text-align-last: right">
              {{ $t("message.creditAfter") }}
            </th>
            <th>{{ $t("message.date") }}</th>
          </thead>
          <tbody>
            <template v-for="(items, index, key) in modalData">
              <tr style="text-align: center">
                <td :id="index + 'tran'">
                  ...{{ items.transactionId.substr(17, 20) }}
                  <b-popovers
                    :target="index + 'tran'.toString()"
                    triggers="hover"
                    placement="top"
                  >
                    <strong>{{ items.transactionId }}</strong>
                  </b-popovers>
                </td>
                <td>{{ items.refNumber }}</td>
                <td>
                  <template v-if="items.type === 'addCredit'">{{
                    $t("message.addCredit")
                  }}</template>
                  <template v-else-if="items.type === 'deductCredit'">{{
                    $t("message.deductCredit")
                  }}</template>
                  <template v-else-if="items.product">{{
                    $t("message.wash")
                  }}</template>
                </td>
                <td style="text-align: right">{{ items.price }}</td>
                <td style="text-align: right">{{ items.acceptorMoney }}</td>
                <td style="text-align: right">{{ items.creditCMBefore }}</td>
                <td style="text-align: right">
                  <template
                    v-if="items.creditCMAfter - items.creditCMBefore > 0"
                    >+{{ items.creditCMAfter - items.creditCMBefore }}</template
                  >
                  <template v-else>{{
                    items.creditCMAfter - items.creditCMBefore
                  }}</template>
                </td>
                <td style="text-align: right">{{ items.creditCMAfter }}</td>
                <td>{{ items.createdAt | moment("YYYY-MM-DD HH:mm") }}</td>
              </tr>
            </template>
          </tbody>
        </table>
        <br />
        <b-pagination
          size="md"
          :total-rows="totalRows2"
          v-model="pagination2"
          :per-page="20"
          @input="pageChanged2(pagination2)"
          v-if="modalData.length !== 0"
        ></b-pagination>
      </div>
    </b-modal>

    <b-modal
      ref="exportModal"
      :title="$t('message.summarize')"
      @hidden="closeExportModal()"
    >
      <table class="table-modal">
        <tr>
          <td>{{ $t("message.refNumber") }} :</td>
          <td>{{ totalRows }} เบอร์</td>
        </tr>
      </table>
      <div slot="modal-footer" class="w-100">
        <download-excel
          class="btn btn-primary"
          :title="title"
          :data="exportData"
          :fields="exportField"
          :name="reportFilename"
        >
          <i class="icon-docs"></i>
          Export
        </download-excel>
      </div>
    </b-modal>

    <b-modal
      ref="resetPasswordModal"
      :title="$t('message.details')"
      size="md"
      @ok="resetPasswrod()"
      :ok-title="$t('message.confirm')"
    >
      <div class="table-responsive">
        <table class="table-modal">
          <tr>
            <td colspan="2">
              <p style="color: red">
                ** รหัสผ่านใหม่จะถูกส่งผ่าน sms ไปยังมือถือลูกค้า
              </p>
            </td>
          </tr>
          <tr>
            <td>{{ $t("message.refNumber") }}</td>
            <td>{{ resetPasswordData.refNumber }}</td>
          </tr>
          <tr>
            <td>{{ $t("message.machineIdFarm") }}</td>
            <td>{{ resetPasswordData.machineId }}</td>
          </tr>
          <tr>
            <td>{{ $t("message.credit") }}</td>
            <td>{{ resetPasswordData.value }}</td>
          </tr>
        </table>
      </div>
    </b-modal>
  </div>
</template>
<script>
import webServices from "../../script";
import {
  ListSelect,
  ModelListSelect,
  MultiListSelect,
} from "../../custom_modules/search-select";
export default {
  name: "customercredits",
  components: {
    ModelListSelect,
    MultiListSelect,
  },
  data() {
    const role = window.localStorage.getItem("roleID")
    return {
      role: role,
      rowData: [],
      totalRows: 0,
      totalRows2: 0,
      searchData: {
        page: 1,
        rows: 30,
      },
      searchDataTrans: {
        page: 1,
        rows: 20,
      },
      pagination: 0,
      pagination2: 0,
      itemperPage: 30,
      type: [
        { value: "addCredit", text: "เพิ่มเครดิต" },
        { value: "deductCredit", text: "หักเครดิต", disabled: role === "admin" ? false : true },
      ],
      creditData: {
        status: "SUCCESS",
        // type: 'credit',
        details: {},
      },
      modalData: [],
      machineData: [],
      title: [],
      exportData: [],
      exportField: {},
      reportFilename: "",
      machineData: [],
      selectedMachine: [],
      resetPasswordData: {},
    };
  },
  methods: {
    getCredits() {
      this.$Progress.start();
      webServices
        .getCredits(this.searchData)
        .then((res) => {
          this.$Progress.finish();
          this.rowData = res.data.rows;
          this.totalRows = res.data.count;
          // console.log(this.rowData)
        })
        .catch((err) => {
          this.$Progress.fail();
          // const error = webServices.catchError(err)
          this.$toast.error({
            title: "ERROR",
            message: "เกิดข้อผิดพลาด",
          });
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
          console.log("error getCredits");
          console.log(err);
        });
    },
    getMachine() {
      this.$Progress.start();
      webServices
        .getMachineList(window.localStorage.getItem("userId"))
        .then((res) => {
          this.$Progress.finish();
          this.machineData = res.data;
        })
        .catch((err) => {
          this.$Progress.fail();
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
          this.$toast.error({
            title: "ERROR",
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText,
            },
          });
          console.log("error @machine");
          console.log(err);
        });
    },
    onSelectMachine(items, lastSelectItem) {
      // console.log(items)
      this.selectedMachine = items;
      this.searchData.machineId = this.selectedMachine.map(
        (data) => data.machineId
      );
    },
    pageChanged(page) {
      this.searchData.page = page;
      this.getCredits();
    },
    pageChanged2(page) {
      // console.log(page)
      this.searchDataTrans.page = page;
      this.getAlltransaction(this.searchDataTrans);
    },
    manageCredit() {
      webServices
        .credits(this.creditData)
        .then((res) => {
          // console.log(res.data)
          if (res.data.success) {
            this.$toast.success({
              title: "SUCCESS",
              message: "สำเร็จ !!",
            });
            // this.creditData.details = {
            //   note: "",
            // };
            // this.creditData.refNumber = "";
            // this.creditData.machineId = "";
            // this.creditData.type = "";
            // this.creditData.price = "";
            this.creditData = {}
            this.getCredits();
          } else {
            const error = webServices.showError(res.data);
            this.$toast.error({
              title: "ERROR",
              message: JSON.stringify(error),
            });
          }
        })
        .catch((err) => {
          console.log("error managerCredit");
          console.log(err);
          this.$toast.error({
            title: "ERROR",
            message: "เกิดข้อผิดพลาด",
          });
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
        });
    },
    showModal(data) {
      this.searchDataTrans.refNumber = data.refNumber;
      this.searchDataTrans.machineId = data.machineId;
      this.searchDataTrans.credits = "true";
      this.$refs.detailsModal.show();
      this.getAlltransaction(this.searchDataTrans);
    },
    showResetPasswordModal(data) {
      this.resetPasswordData = data;
      this.$refs.resetPasswordModal.show();
    },
    getAlltransaction(data) {
      this.$Progress.start();
      webServices
        .getTransactionsReportNew(this.searchDataTrans)
        .then((res) => {
          this.$Progress.finish();
          // console.log(res.data)
          this.modalData = res.data.rows;
          this.totalRows2 = res.data.count;
        })
        .catch((err) => {
          this.$Progress.fail();
          console.log(err);
        });
    },
    getMachine() {
      this.$Progress.start();
      webServices
        .getMachineList(window.localStorage.getItem("userId"))
        .then((res) => {
          this.$Progress.finish();
          this.machineData = res.data;
          // console.log(res.data)
        })
        .catch((err) => {
          this.$Progress.fail();
          console.log("error @machine");
          console.log(err);
          this.$toast.error({
            title: "ERROR",
            message: "เกิดข้อผิดพลาด",
          });
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
        });
    },
    exportFn() {
      console.log("export file");
      this.title[0] = `วันที่ ${new Date()}`;
      this.reportFilename = `Credit.xlsx`;
      this.exportField = {
        เบอร์โทรศัพท์: "refNumber",
        รหัสฟาร์ม: "machineId",
        เครดิต: "value",
        ใช้งานครั้งแรก: "createdAt",
        ใช้งานครั้งล่าสุด: "updatedAt",
      };
      this.exportData = this.rowData.slice();
      this.$refs.exportModal.show();
    },
    closeExportModal() {
      // console.log('close')
      this.exportData.pop();
      this.$refs.exportModal.hide();
    },
    resetPasswrod() {
      webServices
        .resetPasswrod(this.resetPasswordData)
        .then((res) => {
          if (res.data.success) {
            this.$toast.success({
              title: "SUCCESS",
              message: "สำเร็จ",
            });
          } else {
            const errorText = {
              errorCode: res.data.errorCode,
              errorText: res.data.errorText,
            };
            this.$toast.error({
              title: "ERROR",
              message: JSON.stringify(errorText),
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    // if (this.role !== "admin") {
    //   alert("คุณไม่มีสิทธิ์ในการเข้าชมหน้านี้");
    //   window.history.back();
    // } else {
    this.getCredits();
    this.getMachine();
    // }
  },
};
</script>